body {
    font-family: 'Arial', sans-serif;
    background-color: #1e1e2e;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
  
  header {
    background: #27293d;
    padding: 20px 0;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  nav a {
    color: #ffffff;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  nav a:hover {
    color: #f39c12;
  }
  
  .container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  .home {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }

  .section {
    margin-bottom: 40px;
  }
  
  h2 {
    border-bottom: 2px solid #f39c12;
    padding-bottom: 10px;
    margin-bottom: 20px;
  } 
  /* This is for inside the class */
  .about-content {
    display: flex;
    align-items: center;
  }
  
  .about-content p {
    font-size: 1.5em; 
  }
  .about-image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  #contact p {
    font-size: 1.2em; 
  }

  footer {
    background: #27293d;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }
