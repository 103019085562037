.contact-form {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 500px;
    color: #2c2f48;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #2c2f48;
  }
  
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    color:#2c2f48;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-form p {
    margin-top: 10px;
    color: green;
  }

  @media (max-width: 767px) {
    .contact-form {
      width: 100%; /* Adjust as we go */
      padding: 15px;
    }
  }