.navbar-custom {
  background: #27293d !important;
  padding: 20px 0 !important;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  position: relative;
}

.container-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-group {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
}

.icon {
  color: #ffffff;
  text-decoration: none;
  font-size: 2em;
  transition: color 0.3s;
}

.icon:hover {
  color: #f39c12;
}

.title {
  margin-bottom: 10px;
}

.navbar-nav {
  gap: 20px; /* Adds space between nav items */
  font-size: 1.3em;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #f39c12 !important;
}

h1 {
  color:#ffffff
}

h2 {
  border-bottom: 2px solid #f39c12;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color:#ffffff
}

.spotify-playlist-wrapper {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
}

@media (max-width: 767px) {
  .spotify-playlist-wrapper {
    width: 100px; 
    height: auto;
    top: 10px; 
    right: 10px; 
    z-index: 1050; 
  }
}

.navbar {
  position: relative;
  z-index: 1000;
}

.navbar-toggler {
  z-index: 1051; /* Ensure it's above the SpotifyPlaylist */
}

