.projects {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.projects h2 {
    text-align: center;
    margin-bottom: 40px;
    border-bottom: 2px solid #f39c12;
    padding-bottom: 10px;
    color: #f39c12;
    font-size: 30px;
}

.timeline {
    position: relative;
    padding: 10px 0;
    list-style: none;
}

.timeline::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 2px;
    background: #f39c12;
}
