.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    padding: 20px;
    flex-direction: row;
  }
  
  .contact-page .content {
    max-width: 800px;
    width: 100%;
    z-index: 1;
    flex-direction: row;
  }

  .contact-image {
    max-width: 400px; /* Set a max width for the image */
    width: 100%;
    padding: 10px;
  }
  
  .contact-image img {
    width: 100%;
    border-radius: 10px;
  }

  @media (max-width: 767px) {
    .contact-page .content {
      flex-direction: column;
      align-items: center;
    }

    .contact-page .contact-image {
      width: 100%;
      margin-top: 10px;
      order: 2;
    }

    .content-page .content {
      order: 1;
    }
  }