.spotify-playlist {
    background: #f39c12;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 500px;
    color: #2c2f48;
  }
  
  .spotify-playlist h2 {
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
  }
  
  .spotify-playlist iframe {
    border-radius: 12px; 
  }


  @media (max-width: 767px) {
    .spotify-playlist {
      width: 100px; /* Full width for mobile */
      height: auto; /* Adjust height for mobile */
    }
  
    .spotify-playlist iframe {
      height: 80px; /* Adjust height as needed for mobile */
      width: 100%;
    }
  }