.project-card {
  background: #2c2f48;
  border-radius: 10px;
  overflow: hidden;
  perspective: 1000px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding:10px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.card-front {
  background: #f39c12;
  color: #fff;
}

.card-back {
  background: #34495e;
  color: #fff;
  transform: rotateY(180deg);
}

.project-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front h3 {
  margin-bottom: 10px;
}

.card-front p {
  margin-top: 0;
}
