.contact-info {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: #2c2f48;
    max-width: 350px;
  }
  
  .contact-info h2 {
    margin-bottom: 20px;
    color: #2c2f48;
  }
  
  .contact-info .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #2c2f48;
  }
  
  .contact-info .info-item .custom-tooltip {
    display: inline-block;
    margin-left: 20px;
    background: #2c2f48;
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
    position: absolute;
  }

  .contact-info .info-item svg {
    color: #2c2f48; /* Set icon color to blue */
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    .contact-info {
      width: 100%; /* Adjust as we go */
      padding: 15px;
      max-width: 100%;
    }
  }