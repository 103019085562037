.timeline-item {
    position: relative;
    margin: 20px 0;
    padding-left: 40px;
    cursor: pointer;
    transition: transform 0.3s;
}

.timeline-item:not(.expanded):hover {
    transform: scale(1.05);
}

.timeline-item-content {
    padding: 20px;
    background: #2c2f48;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0 , 0, 0.3);
    color:#ffffff;
    transition: max-height 0.3 ease-out;
}

.timeline-item-content .tag {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
}

.timeline-item-content h3 {
    margin-top: 10px;
    color: #f39c12;
    font-size: 20px;
}

.timeline-item-content p {
    margin: 10px 0;
    font-size: 18px;
}

.timeline-item-content .timeline-item-media img {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
}

.timeline-item-content a {
    color: #f39c12;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
    font-size: 15px;
}

.timeline-item-content a:hover {
    color: #ffffff;
}

.timline-item .circle {
    width: 20px;
    height: 20px;
    background: #f39c12;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: -10px;
}

.expanded .timeline-item-content {
    max-height: none;
}

.timeline-item-content .expanded-content {
    margin-top: 10px;
}
