.am-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}
  .about-intro{
    display:flex;
    align-items:center;
    margin-bottom: 40px;
    flex-direction: row-reverse;
  }
  
  .about-photo {
    padding:30px;
    border-radius: 0;
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-left: 20px;
  }
  
  .about-summary{
    max-width: 600px;
  }

  .about-summary h2{
    margin-bottom: 10px;
    color: #f39c12;
  }

  .about-details .about-section {
    display:flex;
    align-items:center;
    margin-bottom: 30px;
  }

  .about-details .about-section .left{
    order:1;
    margin-right: 20x;
  }

  .about-details .about-section .right {
    order: 2;
    margin-left: 20px;
  }
  
  .about-details .about-section .section-text {
    max-width: 600px;
  }
  
  .about-details .about-section h3 {
    margin-bottom: 10px;
    color: #f39c12;
  }

  .about-details .about-section:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .about-details .about-section p,
  .about-details .about-section ul {
    margin: 0 auto;
    max-width: 600px;
  }
  
  .about-details .about-section .section-photo {
    padding: 30px;
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 8px;
    margin: 0 20px;
  }

  
  .personal-interests .photos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

  .personal-interests .photos-grid .section-photo {
    margin: 0 20px;
    width: 100px;
    max-width: 350px;
    height: auto;
    border-radius: 8px;
  }


  header {
    background: #27293d;
    padding: 20px 0;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  h2 {
    border-bottom: 2px solid #f39c12;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  p, li {
    color:#ffffff;
  }

  @media (max-width: 767px) {
    .personal-interests .photos-grid .section-photo {
      width: 50px; /* Adjust as we go. If it gets worst adjust everything */
    }
  }