.ttt-page {
  text-align: center;
  padding: 20px;
}

.video-container {
  position: relative;
  padding-bottom: 28.125%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 50%;
  background: #000;
  margin: 20px auto;
}

.video-container iframe {
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
